import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Código de empleado",
            field: "employeeCode",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Apellidos y nombres",
            field: "employeeName",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Clasificación calibrada",
            field: "calibratedClassificationString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Desempeño estandar 9-Boxes",
            field: "standardPerformanceNineBox",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Desempeño calibrado 9-Boxes",
            field: "calibratedPerformanceNineBox",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Indicador desempeño 9-Boxes",
            field: "indicatorPerformanceNineBox",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: 'IndicatorRenderer'
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Potencial estandar 9-Boxes",
            field: "standardPotentialNineBox",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Potencial calibrado 9-Boxes",
            field: "calibratedPotentialNineBox",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Indicador potencial 9-Boxes",
            field: "indicatorPotentialNineBox",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: 'IndicatorRenderer'
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Clasificación 9-Boxes",
            field: "quadrantClassification",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Clasificación cuadrante",
            field: "quadrantClassificationCode",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: 'NineBoxImageRenderer'
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Nombre del cuadrante",
            field: "quadrantName",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Recomendación",
            field: "recommendation",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Fecha de calibración",
            field: "calibrationDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },

    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Responsable calibración",
            field: "calibrationResponsible",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Comité de calibración",
            field: "calibrationCommitteeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Comentarios de la calibración",
            field: "calibrationComments",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Tipo de funcionario",
            field: "functionaryType",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Lugar de trabajo",
            field: "workplace",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Obra o faena",
            field: "workOrTask",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Centro de costo de gestión",
            field: "costCenter",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Gerencia",
            field: "management",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Departamento",
            field: "department",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Cargo",
            field: "position",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Función",
            field: "function",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Categoría",
            field: "category",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Tipo de contrato",
            field: "contractType",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.nineBoxClassification],
        config: {
            headerName: "Fecha de contratación",
            field: "contractDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
]