import React from "react";
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { getNineBoxColorByCoordinates } from "@icarius-utils/colors";

const CollaboratorTooltipData = ({ data }) => {

  if (!data) return null;

  const standardColor = getNineBoxColorByCoordinates(data.standardQuadrantClassificationCode);
  const calibratedColor = getNineBoxColorByCoordinates(data.quadrantClassificationCode);

  return (
    <Grid container direction="column">
      <Grid
        container item alignItems="center" justify="center"
        style={{
          fontSize: "1.1rem",
          margin: "10px 0px",
          fontWeight: 600,
        }}
      >
        {data.name}
      </Grid>
      {
        data.standardQuadrantClassification &&
        <Grid
          container item alignItems="center"
          style={{
            backgroundColor: standardColor,
            border: `1px ${standardColor} solid`,
            borderRadius: 5,
            padding: 10,
            margin: "5px 0px",
          }}
        >
          <Grid item xs={12}>
            <Typography style={{ fontSize: "1rem", fontWeight: 700, color: 'black' }}>
              {"Clasificación estandar"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 500, color: 'black' }}>
              {`${data.standardQuadrantClassification} - ${data.standardQuadrantName}`}
            </Typography>
          </Grid>
        </Grid>
      }
      {
        data.calibratedClassification &&
        <Grid
          container item alignItems="center"
          style={{
            backgroundColor: calibratedColor,
            border: `1px ${calibratedColor} solid`,
            borderRadius: 5,
            padding: 10,
            margin: "5px 0px",
          }}
        >
          <Grid container item alignItems="center" justify="space-between">
            <Typography style={{ fontWeight: 600, color: 'black' }}>
              {"Clasificación calibrada"}
            </Typography>
            <Typography style={{ fontSize: "0.9rem", fontWeight: 200, color: 'black' }}>
              {data.calibrationDate}
            </Typography>
          </Grid>
          <Typography style={{ fontWeight: 600, color: 'black' }}>
            {`${data.quadrantClassification} - ${data.quadrantName}`}
          </Typography>
        </Grid>
      }
    </Grid>
  );
};

export default CollaboratorTooltipData;