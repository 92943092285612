import React from "react";
import { AnnotationsPage } from "@icarius-pages/annotationsPage";
import useNineBoxClassification from "./useNineBoxClassification";
import CommentDialog from "./dialogs/commentDialog";
import InitialForm from "./initialForm";
import GridPage from "./gridPage";
import NineBoxChartPage from "./chartPage";

const NineBoxClassification = () => {

  const {
    isCalibrationEnabled,
    data,
    yearList,
    performanceProcessList,
    nineBoxDefinition,
    calibrationCommitteeList,
    unassignedEmployeeList,
    isLoading,
    state,
    handlers,
  } = useNineBoxClassification();

  if (state.initialFormIsOpen) {
    return (
      <InitialForm
        isLoading={isLoading}
        yearList={yearList}
        performanceProcessList={performanceProcessList}
        calibrationCommitteeList={calibrationCommitteeList}
        handleSubmit={handlers.handlCloseInitialScreen}
      />
    )
  }

  const subtitle = (() => {
    const performanceName = performanceProcessList[state.settings.year].find((item) => item.key === state.settings.performanceProcess)?.value;
    const calibrationCommitteeName = Boolean(state.settings.calibrationCommittee) && calibrationCommitteeList?.find((item) => item.key === state.settings.calibrationCommittee)?.value;

    return [performanceName, calibrationCommitteeName].filter((item) => Boolean(item)).join(' - ');
  })();

  const shouldHideGridPage = state.committeeCommentDialogIsOpen || state.selectedCollaborators.length > 0 || Boolean(state.annotationsData);

  return (
    <>
      {
        state.committeeCommentDialogIsOpen &&
        <CommentDialog
          open={state.committeeCommentDialogIsOpen}
          handleChange={handlers.handleMovePieceAndComment}
          handleClose={handlers.handleCloseCommitteeCommentDialog}
        />
      }
      {
        state.selectedCollaborators.length > 0 &&
        <NineBoxChartPage
          isLoading={isLoading}
          subtitle={subtitle}
          nineBoxDefinition={nineBoxDefinition}
          collaborators={state.selectedCollaborators}
          handleOpenCommitteeCommentDialog={handlers.handleOpenCommitteeCommentDialog}
          handleCalibratePositions={handlers.handleCalibratePositions}
          handleGoBack={handlers.handleCloseNineBoxChart}
          readOnly={!isCalibrationEnabled}
        />
      }
      {
        Boolean(state.annotationsData) &&
        <AnnotationsPage
          initialYear={Number(state.settings.year)}
          employee={state.annotationsData.employee}
          userDates={state.annotationsData.userDates}
          handleClose={handlers.handleCloseAnnotations}
        />
      }
      {
        !shouldHideGridPage &&
        <GridPage
          isCalibrationEnabled={isCalibrationEnabled}
          subtitle={subtitle}
          data={data}
          calibrationCommitteeList={calibrationCommitteeList}
          unassignedEmployeeList={unassignedEmployeeList}
          isLoading={isLoading}
          state={state}
          handlers={handlers}
        />
      }
    </>
  );
}

export default NineBoxClassification;