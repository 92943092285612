import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  isCalibrationEnabled: false,
  data: [],
  yearList: [],
  performanceProcessList: [],
  calibrationCommitteeList: [],
  unassignedEmployeeList: [],
  nineBoxDefinition: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;

    case actionTypes.INITIAL_GET:
      return { ...state, isLoading: true };
    case actionTypes.INITIAL_GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isCalibrationEnabled: action.payload.isCalibrationEnabled,
        yearList: action.payload.yearList,
        performanceProcessList: action.payload.performanceProcessList,
        calibrationCommitteeList: action.payload.calibrationCommitteeList,
      };
    case actionTypes.INITIAL_GET_REJECTED:
      return { ...state, isLoading: false };


    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        unassignedEmployeeList: action.payload.unassignedEmployeeList,
        calibrationCommitteeList: action.payload.calibrationCommitteeList,
        nineBoxDefinition: action.payload.nineBoxDefinition,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        unassignedEmployeeList: action.payload.unassignedEmployeeList,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        unassignedEmployeeList: action.payload.unassignedEmployeeList,
      };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        unassignedEmployeeList: action.payload.unassignedEmployeeList,
      };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
