import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createNineBoxClassificationAction } from "../../../actions";
import { useDispatch } from "react-redux";
import Loader from "@icarius-common/loader";

const CreateDialog = (props) => {

  const {
    open,
    unassignedEmployeeList,
    isLoading,
    handleClose,
    settings,
  } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createNineBoxClassificationAction(
      {
        employeeCode: dataToSend.collaborator,
        potentialProcess: settings.potentialProcess,
        performanceProcess: settings.performanceProcess
      }
    ))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(create, openValidationError);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Crear clasificación 9-box"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-collaborator`}>{"Colaborador"}</InputLabel>
            <Select
              value={formData.collaborator}
              labelId={`label-collaborator`}
              id={`select-collaborator`}
              onChange={(e) => setFormValue(e.target.value, "collaborator")}
              margin={"none"}
            >
              {
                unassignedEmployeeList.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateDialog;