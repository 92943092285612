import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { withStyles } from "@material-ui/core/styles";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTransition from "@icarius-common/dialogTransition";

const styles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    cssOutlinedInput: {
        color: "var(--mainText) !important",
        '&$cssFocused $notchedOutline': {
            color: "var(--mainText) !important",
        }
    },

    cssFocused: {
        color: "var(--mainText) !important",
    },

    notchedOutline: {
        borderColor: 'var(--icons) !important',
        color: "var(--mainText) !important",
    },
});

const CommentDialog = (props) => {

    const {
        open,
        handleChange,
        handleClose,
        classes,
    } = props;

    const [value, setValue] = useState("");

    const handleSubmit = () => {
        handleChange(value);        
        handleClose();
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'sm'}
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {'Calibración'}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <TextField
                    autoFocus
                    fullWidth
                    margin={"none"}
                    label={'Comentarios de la calibración'}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                    multiline={true}
                    variant="outlined"
                    rows={4}
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        },
                    }}
                    InputProps={{
                        spellCheck: 'false',
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
                <ButtonDialogAction onClick={handleSubmit} isAccept text={'Guardar'} />
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(CommentDialog);
