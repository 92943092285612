import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getNineBoxClassificationAPI,
  getInitialNineBoxClassificationAPI,
  modifyNineBoxClassificationAPI,
  createNineBoxClassificationAPI,
  deleteNineBoxClassificationAPI,
} from "@icarius-connection/api";

export const getInitialNineBoxClassificationAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.INITIAL_GET });
  try {

    let response = await getInitialNineBoxClassificationAPI();

    let isCalibrationEnabled = response.data && response.data.isCalibrationEnabled;
    let yearList = response.data && response.data.yearList;
    let performanceProcessList = response.data && response.data.performanceProcessList;
    let calibrationCommitteeList = response.data && response.data.calibrationCommitteeList;

    dispatch({
      type: actionTypes.INITIAL_GET_FULFILLED,
      payload: {
        yearList,
        performanceProcessList,
        calibrationCommitteeList,
        isCalibrationEnabled,
      }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.INITIAL_GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getNineBoxClassificationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {

    let response = await getNineBoxClassificationAPI(dataToSend);

    let data = response.data && response.data.data;
    let unassignedEmployeeList = response.data && response.data.unassignedEmployeeList;
    let calibrationCommitteeList = response.data && response.data.calibrationCommitteeList;
    let nineBoxDefinition = response.data && response.data.nineBoxDefinition;

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        unassignedEmployeeList,
        calibrationCommitteeList,
        nineBoxDefinition,
      }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createNineBoxClassificationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {

    let response = await createNineBoxClassificationAPI(dataToSend);

    let data = response.data && response.data.data;
    let unassignedEmployeeList = response.data && response.data.unassignedEmployeeList;

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: {
        data,
        unassignedEmployeeList,
      }
    });

    dispatch(openSnackbarAction({ msg: 'Registro creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const modifyNineBoxClassificationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {

    let response = await modifyNineBoxClassificationAPI(dataToSend);

    let data = response.data && response.data.data;
    let unassignedEmployeeList = response.data && response.data.unassignedEmployeeList;

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: {
        data,
        unassignedEmployeeList,
      }
    });

    dispatch(openSnackbarAction({ msg: 'Registros modificados con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteNineBoxClassificationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {

    let response = await deleteNineBoxClassificationAPI(dataToSend);

    let data = response.data && response.data.data;
    let unassignedEmployeeList = response.data && response.data.unassignedEmployeeList;

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: {
        data,
        unassignedEmployeeList,
      }
    });

    dispatch(openSnackbarAction({ msg: 'Registros borrados con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};