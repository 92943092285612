import React from "react";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { modifyNineBoxClassificationAction } from "../../../actions";
import useHandleForm from "./useHandleForm";
import Loader from "@icarius-common/loader";

const gridStyle = { height: 75, padding: "0px 10px" };
const gridStyleNoHeight = { padding: "0px 10px", marginTop: "15px" };

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
  },
})(Dialog);

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});


const EditDialog = (props) => {

  const {
    open,
    calibrationCommitteeList,
    settings,
    isLoading,
    data,
    handleClose,
    classes,
  } = props;

  const dispatch = useDispatch();

  const modify = (dataToSend) => {
    dispatch(modifyNineBoxClassificationAction(
      {
        data: [{
          ...dataToSend,
          potentialProcess: settings.potentialProcess,
          performanceProcess: settings.performanceProcess,
        }]
      }
    ))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, modify, openValidationError);

  return (
    <>
      <StyledDialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {"Editar clasificación 9-box"}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent style={{ paddingTop: 0 }}>
            <Loader open={isLoading} />
            <Grid container item xs={12}>
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* performance */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-calibratedPerformanceNineBox`}>
                    {'Desempeño calibrado desde 9-Boxes (1 a 3)'}
                  </InputLabel>
                  <Select
                    value={formData.calibratedPerformanceNineBox}
                    labelId={`label-calibratedPerformanceNineBox`}
                    id={`select-calibratedPerformanceNineBox`}
                    onChange={(e) => setFormValue(e.target.value, "calibratedPerformanceNineBox")}
                    margin={"none"}
                  >
                    {
                      ["1", "2", "3"].map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item}
                          value={item}>
                          {item}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* potential */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-calibratedPotentialNineBox`}>
                    {'Potencial calibrado desde 9-Boxes (1 a 3)'}
                  </InputLabel>
                  <Select
                    value={formData.calibratedPotentialNineBox}
                    labelId={`label-calibratedPotentialNineBox`}
                    id={`select-calibratedPotentialNineBox`}
                    onChange={(e) => setFormValue(e.target.value, "calibratedPotentialNineBox")}
                    margin={"none"}
                  >
                    {
                      ["1", "2", "3"].map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item}
                          value={item}>
                          {item}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-status`}>{"Comité de calibración"}</InputLabel>
                  <Select
                    value={formData.calibrationCommittee}
                    labelId={`label-calibrationCommittee`}
                    id={`select-calibrationCommittee`}
                    onChange={(e) => setFormValue(e.target.value, "calibrationCommittee")}
                    margin={"none"}
                  >
                    {
                      calibrationCommitteeList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyleNoHeight}>
                <TextField
                  fullWidth
                  margin={"none"}
                  label={'Comentarios de la calibración'}
                  value={formData.calibrationComments}
                  onChange={(e) => setFormValue(e.target.value, "calibrationComments")}
                  multiline={true}
                  variant="outlined"
                  rows={4}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    spellCheck: 'false',
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
            <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
          </DialogActions>
        </div>
      </StyledDialog>
    </>
  );
}

export default withStyles(styles)(EditDialog);