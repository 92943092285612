import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import {
  NineBoxSettingsIcon, NineBoxClassificationIcon
} from "@icarius-icons/index";

const Index = () => {

  const data = [
    {
      name: "Definición de 9-Boxes",
      icon: <NineBoxSettingsIcon />,
      path: paths.nineBoxSettings,
    },
    {
      name: "Clasificación 9-Boxes",
      icon: <NineBoxClassificationIcon />,
      path: paths.nineBoxClassification,
    },
  ];

  return (
    <ScreenSelector
      title={"Clasificación del talento"}
      data={data}
    />
  );
}

export default Index;
