import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsLoading,
  getData,
  getYearList,
  getPerformanceProcessList,
  getCalibrationCommitteeList,
  getUnassignedEmployeeList,
  getNineBoxDefinition,
  getIsCalibrationEnabled,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  deleteNineBoxClassificationAction,
  getNineBoxClassificationAction,
  getInitialNineBoxClassificationAction,
  modifyNineBoxClassificationAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { getAnnotationsAction } from "@icarius-pages/annotationsPage/actions";
import { getIsLoading as getIsAnnotationsLoading } from "@icarius-pages/annotationsPage/selectors";
import { getUserData } from "src/app/selectors";

const useNineBoxClassification = () => {

  const [initialFormIsOpen, setInitialFormIsOpen] = useState(true);
  const [settings, setSettings] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null); // seleccionado para editar por modal
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);

  const [selectedCollaborators, setSelectedCollaborators] = useState([]); // seleccionado para el 9box
  const [committeeCommentDialogIsOpen, setCommitteeCommentDialogIsOpen] = useState(false);
  const [selectedNineBoxNode, setSelectedNineBoxNode] = useState(null);

  const [annotationsData, setAnnotationsData] = useState(null);

  const dispatch = useDispatch();

  const userData = useSelector(getUserData);
  const data = useSelector(getData);
  const isLoading = useSelector(getIsLoading);
  const isAnnotationsLoading = useSelector(getIsAnnotationsLoading);
  const isCalibrationEnabled = useSelector(getIsCalibrationEnabled);
  const yearList = useSelector(getYearList);
  const performanceProcessList = useSelector(getPerformanceProcessList);
  const calibrationCommitteeList = useSelector(getCalibrationCommitteeList);
  const unassignedEmployeeList = useSelector(getUnassignedEmployeeList);
  const nineBoxDefinition = useSelector(getNineBoxDefinition);

  useEffect(() => {
    dispatch(getInitialNineBoxClassificationAction());

    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenInitialScreen = () => {
    dispatch(getInitialNineBoxClassificationAction())
      .then(() => {
        setInitialFormIsOpen(true);

        // Reinicio los state que contienen datos
        setSettings(null);
        setSelectedNineBoxNode(null);
        setSelectedCollaborators([])
        setSelectedRow(null);
      });
  }

  const handlCloseInitialScreen = (dataToSend) => {
    dispatch(getNineBoxClassificationAction(dataToSend))
      .then(() => {
        setSettings(dataToSend);
        setInitialFormIsOpen(false);
      });
  }

  const handleOpenAnnotations = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    const data = selectedRows[0];

    if (data.employeeCode === userData.code) {
      dispatch(openSnackbarAction({ msg: 'No puede seleccionarse a sí mismo', severity: "warning" }));
      return;
    }

    dispatch(getAnnotationsAction(data.employeeCode))
      .then(() => setAnnotationsData({
        employee: {
          "CODIGO DE EMPLEADO": data.employeeCode,
          "APELLIDO Y NOMBRE": data.employeeName,
          "MANAGER": data.manager,
        },
        userDates: {
          "Fecha contratacion": data.contractDate,
          "Fecha termino contrato": data.contractEndDate,
        }
      }));
  }

  const handleCloseAnnotations = () => {
    setAnnotationsData(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    const dataToSend = selectedRows.map((item) => ({
      employeeCode: item.employeeCode,
      potentialProcess: settings.potentialProcess,
      performanceProcess: settings.performanceProcess
    }));

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Seguro que desea eliminar los registros de clasificación 9-Boxes seleccionados?",
      onConfirm: () => dispatch(deleteNineBoxClassificationAction({ data: dataToSend })),
    }));
  }

  const handleOpenCreateDialog = () => {
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedRow(selectedRows[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedRow(null);
  }

  const handleOpenNineBoxChart = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    setSelectedCollaborators(selectedRows);
  }

  const handleCloseNineBoxChart = (collaborators) => {
    if (isCalibrationEnabled) {
      const movedCollaborators = collaborators.filter((item) => item.moved);

      if (movedCollaborators.length) {
        dispatch(openDialogAction({
          title: 'Atención',
          msg: 'Hay cambios sin guardar, ¿desea salir de todos modos?',
          onConfirm: () => setSelectedCollaborators([]),
        }))
        return;
      }
    }

    setSelectedCollaborators([]);
  }

  const handleCalibratePositions = (collaborators) => {
    const movedCollaborators = collaborators.filter((item) => item.moved);

    if (!movedCollaborators.length) {
      dispatch(openSnackbarAction({ msg: 'No hay registros calibrados para actualizar', severity: "info" }));
      return;
    }

    let formattedColaborators = movedCollaborators
      .filter((item) => item.moved)
      .map((item) => {
        return {
          performanceProcess: settings.performanceProcess,
          potentialProcess: settings.potentialProcess,
          employeeCode: item.code,
          calibratedPotentialNineBox: `${item.position.y}`,
          calibratedPerformanceNineBox: `${item.position.x}`,
          calibrationComments: item.calibrationComments || "",
          calibrationCommittee: settings.calibrationCommittee,
        };
      });

    dispatch(modifyNineBoxClassificationAction({ data: formattedColaborators }))
      .then((resp) => {
        if (resp.status === 200) {
          const updatedCollaborators = resp?.data?.data;
          const currentlyLoadedCollaborators = selectedCollaborators.map((item) => item.employeeCode);
          const filteredCollaborators = updatedCollaborators.filter((item) => currentlyLoadedCollaborators.includes(item.employeeCode));
          setSelectedCollaborators(filteredCollaborators);
        }
      });
  }

  const handleOpenCommitteeCommentDialog = (boardLogic, item, x, y) => {
    setSelectedNineBoxNode({ boardLogic, item, x, y });
    setCommitteeCommentDialogIsOpen(true);
  }

  const handleCloseCommitteeCommentDialog = () => {
    setCommitteeCommentDialogIsOpen(false);
    setSelectedNineBoxNode(null);
  }

  const handleMovePieceAndComment = (newComment) => {
    const { boardLogic, item, x, y } = selectedNineBoxNode;
    boardLogic.movePiece(item, x, y, newComment);
  }

  const state = {
    initialFormIsOpen,
    settings,
    selectedRow,
    createDialogIsOpen,
    editDialogIsOpen,
    selectedCollaborators,
    committeeCommentDialogIsOpen,
    selectedNineBoxNode,
    annotationsData,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleOpenInitialScreen,
    handlCloseInitialScreen,
    handleOpenNineBoxChart,
    handleCloseNineBoxChart,
    handleCalibratePositions,
    handleOpenCommitteeCommentDialog,
    handleCloseCommitteeCommentDialog,
    handleMovePieceAndComment,
    handleOpenAnnotations,
    handleCloseAnnotations,
  }

  return {
    isCalibrationEnabled,
    data,
    yearList,
    performanceProcessList,
    calibrationCommitteeList,
    nineBoxDefinition,
    unassignedEmployeeList,
    state,
    handlers,
    isLoading: isLoading || isAnnotationsLoading,
  }
}

export default useNineBoxClassification;