import React from "react";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getLocalizedString } from "@icarius-localization/strings";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import IndicatorRenderer from "@icarius-table/renderersAndFilters/indicatorRenderer";
import NineBoxImageRenderer from "@icarius-table/renderersAndFilters/nineBoxImageRenderer";
import CreateDialog from "./dialogs/createDialog";
import EditDialog from "./dialogs/editDialog";

const GridPage = (props) => {

  const {
    isLoading,
    isCalibrationEnabled,
    data,
    subtitle,
    calibrationCommitteeList,
    unassignedEmployeeList,
    state,
    handlers,
  } = props;

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('NINE_BOX_CLASSIFICATION');

  const customHeader = (gridRef) => (
    <>
      <TemplatesHeader
        gridRef={gridRef}
        user={user}
        templates={templates}
        selectedTemplate={selectedTemplate}
        getRole={getRole}
        handleImportTemplates={handleImportTemplates}
        handleExportTemplates={handleExportTemplates}
        handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
        handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
        handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
        handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
      />
    </>
  )

  const child = (gridRef) => (
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
      {
        state.createDialogIsOpen &&
        <CreateDialog
          open={state.createDialogIsOpen}
          isLoading={isLoading}
          settings={state.settings}
          unassignedEmployeeList={unassignedEmployeeList}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
      {
        state.editDialogIsOpen &&
        <EditDialog
          open={state.editDialogIsOpen}
          isLoading={isLoading}
          data={state.selectedRow}
          settings={state.settings}
          calibrationCommitteeList={calibrationCommitteeList}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
    </>
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handlers.handleOpenInitialScreen}
      type={"goBack"}
    />
  )

  const previewButton = (gridRef) => (
    <CustomIconButton
      title={'Ver'}
      onClick={() => handlers.handleOpenNineBoxChart(gridRef)}
      type={"preview"}
    />
  )

  const annotationsButton = (gridRef) => (
    <CustomIconButton
      title={'Anotaciones'}
      onClick={() => handlers.handleOpenAnnotations(gridRef)}
      type={"analytics"}
    />
  )

  const createButton = () => isCalibrationEnabled && (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => isCalibrationEnabled && (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => isCalibrationEnabled && (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  return (
    <CommonPage
      title={`Clasificación 9-Boxes: ${subtitle}`}
      gridTitle={`Clasificación ${subtitle}`}
      menuItems={[goBackButton, previewButton, annotationsButton, createButton, modifyButton, deleteButton]}
      customHeader={customHeader}
      columnDefPage={paths.nineBoxClassification}
      rowData={data}
      isLoading={isLoading || isLoadingTemplates}
      hasSelectAll
      hasExpand
      hasHelp
      frameworkComponents={{ IndicatorRenderer, NineBoxImageRenderer }}
    >
      {child}
    </CommonPage>
  );
}

export default GridPage;
