import React from "react";
import { Typography } from "@material-ui/core";
import NineBoxChart from "@icarius-common/nineBoxChart";

const NineBoxPreviewChart = ({ data }) => {

  const dataToUse = data ? [...data] : []; // como voy a hacer sort, tengo que hacer una copia para que no ordene el original
  const nineBoxDefinition = dataToUse.sort((a, b) => (a.code > b.code) ? 1 : -1) || [];

  const nineBoxInitialData = dataToUse.map((item) => {
    const [x, y] = item.code.split(',');
    return ({
      position: {
        x: Number(x),
        y: Number(y),
      },
    })
  }) || [];

  if (!data?.length) return null;

  return (
    <NineBoxChart
      initialData={nineBoxInitialData}
      nineBoxDefinition={nineBoxDefinition}
      renderPieceContent={({ boxDefinition }) => {
        return (
          <>
            <Typography align="center" style={{ fontWeight: 600 }}>
              {boxDefinition.quadrant}
            </Typography>
            <Typography align="center">
              {boxDefinition.name}
            </Typography>
          </>
        )
      }}
    />
  )
}

export default NineBoxPreviewChart;