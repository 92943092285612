import React from "react";
import { useDispatch } from "react-redux";
import { TextField, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { modifyNineBoxSettingsAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { withStyles } from "@material-ui/core/styles";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const { data, handleClose, classes } = props;
  const dispatch = useDispatch();

  const modify = (validatedData) => {
    const dataToSend = { ...data, ...validatedData };
    dispatch(modifyNineBoxSettingsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={'Posición del cuadrante (Y,X)'}
            value={data.code}
            inputProps={{ maxLength: 3 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* cuadrante */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={'Número'}
            value={data.quadrant}
            inputProps={{ maxLength: 1 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
          <TextField
            fullWidth
            required
            margin={"none"}
            label={'Nombre del cuadrante'}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>  <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* recomendación */}
          <TextField
            fullWidth
            margin={"none"}
            label={'Recomendación'}
            value={formData.recommendation}
            onChange={(e) => setFormValue(e.target.value, "recommendation")}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* descripcion */}
          <TextField
            required
            fullWidth
            label={'Descripción del cuadrante'}
            variant="outlined"
            multiline
            rows={5}
            margin={"none"}
            value={formData.description}
            onChange={(e) => setFormValue(e.target.value, "description")}
            inputProps={{ spellCheck: 'false' }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* descripcion */}
          <TextField
            fullWidth
            label={'Características del cuadrante'}
            variant="outlined"
            multiline
            rows={5}
            margin={"none"}
            value={formData.quadrantCharacteristics}
            onChange={(e) => setFormValue(e.target.value, "quadrantCharacteristics")}
            inputProps={{ spellCheck: 'false' }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Form);
