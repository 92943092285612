import React, { useState } from "react";
import { Grid, Card, ListItem } from "@material-ui/core";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { makeStyles } from "@material-ui/core/styles";
import { Draggable } from 'react-beautiful-dnd';
import Form from "./form";

const useStyles = makeStyles({
  noPadding: {
    padding: '0px'
  }
});

const DataItem = (props) => {

  const {
    data,
    color,
    index,
  } = props;
  
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false); //si viene sin data, que este abierto por defecto

  const handleCloseForm = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const item = (
    <Card style={{ width: "100%", borderRadius: 10, background: isOpen ? color : "", color: isOpen && 'white' }}>
      <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
        <Grid container item xs={1}>
          <DragIndicatorIcon />
        </Grid>
        <Grid container item xs={11} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleCloseForm}>
          {
            <>
              <DataContainer sm={12} md={2} lg={2} text={`Y,X: ${data.code}`} />
              <DataContainer sm={12} md={4} lg={4} text={data.name} />
              <DataContainer sm={12} md={6} lg={6} text={data.recommendation} />
            </>
          }
        </Grid>
      </Grid>
    </Card>
  );

  return (
    <>
      <Draggable draggableId={data.code} index={index}>
        {(provided) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classes.noPadding}
          >
            {item}
          </ListItem>
        )}
      </Draggable>
      {
        isOpen &&
        <Form
          data={data}
          handleClose={handleCloseForm}
        />
      }
    </>
  );
}

export default DataItem;
