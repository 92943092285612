import React, { useState, useMemo } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CommonPage from "@icarius-common/commonPage";
import NineBoxChart from "@icarius-common/nineBoxChart";
import Collaborator from "./collaborator";
import QuadrantTooltipData from "./quadrantTooltipData";

const NineBoxChartPage = (props) => {

	const {
		readOnly,
		isLoading,
		subtitle,
		collaborators,
		nineBoxDefinition,
		handleOpenCommitteeCommentDialog,
		handleCalibratePositions,
		handleGoBack,
	} = props;

	const [calibratedData, setCalibratedData] = useState(null);

	const goBackItem = () => (
		<CustomIconButton
			title={'Volver'}
			onClick={() => handleGoBack(calibratedData)}
			type={"goBack"}
		/>
	)

	const saveItem = () => !readOnly && (
		<CustomIconButton
			title={'Guardar calibraciones'}
			onClick={() => handleCalibratePositions(calibratedData)}
			type={"save"}
		/>
	)

	const renderQuadrantTooltipContent = (data, piecesOnSquareAmount) => {
		if (piecesOnSquareAmount || !data) return '';
		return <QuadrantTooltipData data={data} />
	};

	const formattedColaborators = useMemo(() => {
		return collaborators.map((item) => {
			return {
				...item,
				code: item.employeeCode,
				name: item.employeeName,
				position: {
					x: parseInt(item.calibratedPerformanceNineBox),
					y: parseInt(item.calibratedPotentialNineBox),
				}
			};
		});
	}, [collaborators]);

	return (
		<CommonPage
			title={`Clasificación 9-Boxes: ${subtitle}`}
			menuItems={[goBackItem, saveItem]}
			isLoading={isLoading}
			isNotGridPage
		>
			<NineBoxChart
				initialData={formattedColaborators}
				nineBoxDefinition={nineBoxDefinition}
				onPieceDrop={readOnly ? null : handleOpenCommitteeCommentDialog}
				onChange={readOnly ? null : setCalibratedData}
				renderQuadrantTooltipContent={renderQuadrantTooltipContent}
				renderPieceContent={(props) => <Collaborator {...props} />}
			/>
		</CommonPage>
	)
}

export default NineBoxChartPage;