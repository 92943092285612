import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNineBoxSettingsAction, orderNineBoxSettingsAction } from "../actions";
import { getLoading, getData } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import NineBoxPreviewChart from "./nineBoxPreviewChart";

export const TypeContext = React.createContext("");

const NineBoxSettings = ({ history }) => {
  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getNineBoxSettingsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const [items, setItems] = useState(data);

  useEffect(() => {
    // actualizar setItems cuando el array se modifique
    setItems(data)
  }, [data])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);
  };

  const handleSaveOrder = () => {
    const dataToSend = items.map((item) => item.code);
    dispatch(orderNineBoxSettingsAction({ codes: dataToSend }));
  }

  return (
    <ABMLayout
      title={`Definición de 9-Boxes`}
      isLoading={isLoading}
      showCreateButton={false}
      showGoBack
      handleGoBack={() => history.push(paths.talentClassification)}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Grid container item xs={12} direction="row">
                {
                  items?.map((item, index) => (
                    <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
                      <DataItem data={item} color={color} index={index} />
                    </Grid>
                  ))
                }
                {provided.placeholder}
              </Grid>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {
        Boolean(items?.length) &&
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
          <ButtonDialogAction isAccept onClick={handleSaveOrder} text={'Guardar orden'} />
        </Grid>
      }
      <NineBoxPreviewChart data={data} />
    </ABMLayout>
  )
}

export default NineBoxSettings;