import React from "react";
import { Tooltip } from '@material-ui/core';
import ProfileImg from "@icarius-common/profileImg";
import QuadrantTooltipData from "./quadrantTooltipData";
import CollaboratorTooltipData from "./collaboratorTooltipData";

const Collaborator = ({ item, boxDefinition }) => {

  return (
    <Tooltip
      arrow
      title={
        <>
          <CollaboratorTooltipData data={item} />
          <QuadrantTooltipData data={boxDefinition} />
        </>
      }
    >
      <div>
        <ProfileImg
          size={48}
          image={item.image}
        />
      </div>
    </Tooltip>
  );
};

export default Collaborator;