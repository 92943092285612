import React from "react";
import { Typography } from '@material-ui/core';

const QuadrantTooltipData = ({ data }) => {

  if (!data) return null;

  return (
    <>
      <Typography
        align="center"
        style={{
          fontSize: "1.1rem",
          margin: "10px 0px",
          fontWeight: 600,
        }}
      >
        {`${data.key} - ${data.name}`}
      </Typography>
      <Typography align="center" style={{ fontSize: '0.95rem' }}>
        {data.value}
      </Typography>
      {
        Boolean(data?.recommendation) &&
        <Typography align="center" style={{ fontSize: '0.9rem' }}>
          {`(${data?.recommendation || ''})`}
        </Typography>
      }
    </>
  );
};

export default QuadrantTooltipData;